<template>
  <v-card outlined color="grey lighten-4">
    <v-list-item three-line>
      <div :class="{ 'd-flex flex-no-wrap': !$vuetify.breakpoint.smAndDown }">
        <v-list-item-avatar tile size="230px" color="grey">
          <img alt="user" :src="item.photo" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="text-h5">
            {{ item.name }}
            <DeleteNewComer
              v-if="authority.staff"
              @my-click="deleteNewComer(item._id)"
            ></DeleteNewComer>
            <EditNewComer
              :item="item"
              v-if="authority.staff"
              @my-click="$emit('update')"
            ></EditNewComer>
          </v-list-item-title>
          <v-list-item-subtitle>{{ item.career }} </v-list-item-subtitle>
          <v-divider></v-divider>
          <v-sheet :height="height" color="grey lighten-4">
            <v-card-text :class="comment">
              {{ item.comment }}
            </v-card-text>
          </v-sheet>
        </v-list-item-content>
      </div>
    </v-list-item>
  </v-card>
</template>
<script>
import axios from "axios";
import DeleteNewComer from "@/components/staff/DeleteNewComer";
import EditNewComer from "@/components/staff/EditNewComer";
export default {
  components: { DeleteNewComer, EditNewComer },
  props: ["item", "authority"],
  data() {
    return {
      comment: "comment pl-1",
    };
  },
  watch: {},
  moutend() {},
  methods: {
    deleteNewComer(_id) {
      axios
        .post(process.env.VUE_APP_API_URL + "/delete-newcomer", { _id: _id })
        .then(() => {
          this.$emit("delete");
        });
    },
  },
};
</script>
<style>
.comment {
  white-space: pre-line;
}
</style>