<template>
  <v-card>
    <v-tabs v-model="tab" background-color="white" grow>
      <v-tab v-for="item in items" :key="item.tab">
        {{ item.tab }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item v-for="item in items" :key="item.tab">
        <v-card flat>
          <StaffInfo v-show="item.content == 'staff'"></StaffInfo>
          <NewComer v-show="item.content == 'newcomer'"></NewComer>
        </v-card>      
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>
<script>
import StaffInfo from "@/components/staff/StaffInfo";
import NewComer from "@/components/staff/NewComer";

export default {
  components: { StaffInfo, NewComer },
  data() {
    return {
      tab: 0,
      items: [
        { tab: "社員情報", content: "staff" },
        { tab: "新人情報", content: "newcomer" },
      ],
    };
  },
};
</script>