<template>
  <div>
    <v-snackbar top v-model="snackbar" timeout="3000"> {{ msg }} </v-snackbar>
    <v-expansion-panels
      v-if="authority.staff && !loading && newComerListHidden.length != 0"
    >
      <v-expansion-panel>
        <v-expansion-panel-header class="text-subtitle-1" color="grey"
          ><v-sheet dark color="grey">未掲載</v-sheet>
        </v-expansion-panel-header>

        <v-expansion-panel-content
          class="mt-3"
          v-for="item in newComerListHidden"
          :key="item._id"
        >
          <NewComerProfile
            :item="item"
            :authority="authority"
            @delete="deleteNewComer()"
            @update="editNewComer()"
          ></NewComerProfile>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-expansion-panels v-model="panel" :key="refkey">
      <v-expansion-panel v-for="itemList in newComerList" :key="itemList.index">
        <v-expansion-panel-header class="text-subtitle-1" color="blue lighten-4"
          ><div>{{ itemList[0] }}年度</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content
          class="mt-3"
          v-for="item in itemList[1]"
          :key="item._id"
        >
          <NewComerProfile
            :item="item"
            :authority="authority"
            @delete="deleteNewComer()"
            @update="editNewComer()"
          ></NewComerProfile>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-container v-show="loading">
      <v-row justify="center">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-row>
    </v-container>
    <AddNewComer v-if="authority.staff" @my-click="addNewComer()"></AddNewComer>
  </div>
</template>
<script>
import AddNewComer from "@/components/staff/AddNewComer";
import NewComerProfile from "@/components/staff/NewComerProfile";

import _ from "lodash";
// import moment from "moment";
import axios from "axios";

export default {
  components: { AddNewComer, NewComerProfile },
  data() {
    return {
      loading: false,
      panel: 0,
      refkey: 0,
      msg: "",
      snackbar: false,
      authority: "",
      newComerList: [],
      newComerListHidden: [],
    };
  },
  computed: {},
  methods: {
    getUserAuthority() {
      axios
        .get(process.env.VUE_APP_API_URL + "/get-user-authority")
        .then((response) => {
          this.authority = response.data.authority;
        });
    },
    getNewComer() {
      axios
        .get(process.env.VUE_APP_API_URL + "/get-newcomer")
        .then((response) => {
          this.newComerList.splice(0);
          this.newComerListHidden.splice(0);
          var groupingData = this.grouping(response.data.newComerList, "nendo");
          groupingData = Object.entries(groupingData);
          groupingData = _.sortBy(groupingData, "year").reverse();
          groupingData.forEach((elem) => {
            this.newComerList.push(elem);
          });
          response.data.newComerListHidden.forEach((elem) => {
            this.newComerListHidden.push(elem);
          });
        });
    },
    grouping(xs, key) {
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    },
    deleteNewComer() {
      this.msg = "削除しました";
      this.snackbar = true;
      this.getNewComer();
    },
    editNewComer() {
      this.msg = "更新しました";
      this.snackbar = true;
      this.getNewComer();
    },
    addNewComer() {
      this.msg = "登録しました";
      this.snackbar = true;
      this.getNewComer();
    },
  },
  mounted() {
    this.getUserAuthority();
    this.loading = true;
    setTimeout(() => {
      this.getNewComer();
      this.loading = false;
    }, 1500);
  },
};
</script>
<style>
</style>
