<template>
  <div>
    <v-snackbar top v-model="snackbar" timeout="3000"> {{ msg }} </v-snackbar>
    <div v-if="authority.staff && staffinfoListHidden.length != 0" class="pa-5">
      <v-sheet class="pa-3" color="grey" dark rounded> 未掲載</v-sheet>
      <v-data-table
        disable-pagination
        hide-default-footer
        :headers="headersForHidden"
        :items="staffinfoListHidden"
        item-key="_id"
        class="elevation-1 pa-5"
        dense
        background-color="grey"
      >
        <template v-slot:[`item.type`]="{ item }">
          <v-chip small dark :color="colorList[item.type]">{{
            item.text
          }}</v-chip>
        </template>
        <template v-slot:[`item.editDelete`]="{ item }">
          <EditStaffInfo
            :typeList="typeList"
            :editItem="item"
            @my-click="updateStaffInfo()"
          >
          </EditStaffInfo>
          <DeleteStaffInfo
            @my-click="deleteStaffInfo(item._id)"
          ></DeleteStaffInfo>
        </template>
      </v-data-table>
    </div>

    <v-data-table
      :headers="headers"
      :items="staffinfoList"
      item-key="_id"
      class="elevation-1 pa-5"
      dense
    >
      <template v-slot:top>
        <v-container>
          <v-row justify="end" no-gutters>
            <v-col cols="12" md="6">
              <v-row>
                <!-- Filter for calories -->
                <v-select
                  chips
                  prepend-icon="mdi-filter-variant"
                  multiple
                  :items="typeList"
                  v-model="selectedTypeList"
                  item-text="text"
                  item-value="type"
                >
                  <template #selection="{ item }">
                    <v-chip small dark :color="colorList[item.type]">{{
                      item.text
                    }}</v-chip>
                  </template>
                </v-select>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:[`item.date`]="{ item }">
        <v-icon class="pr-3" color="pink" v-if="item.new">mdi-new-box</v-icon
        >{{ item.date }}
      </template>
      <template v-slot:[`item.type`]="{ item }">
        <v-chip small dark :color="colorList[item.type]">{{
          item.text
        }}</v-chip>
      </template>

      <template v-slot:[`item.editDelete`]="{ item }" v-if="authority.staff">
        <EditStaffInfo
          :editItem="item"
          :typeList="typeList"
          @my-click="updateStaffInfo()"
        >
        </EditStaffInfo>
        <DeleteStaffInfo
          @my-click="deleteStaffInfo(item._id)"
        ></DeleteStaffInfo>
      </template>
    </v-data-table>
    <AddStaffInfo
      v-if="authority.staff"
      :typeList="typeList"
      @my-click="getStaffInfo()"
    ></AddStaffInfo>
  </div>
</template>
<script>
import _ from "lodash";
import moment from "moment";
import axios from "axios";
import AddStaffInfo from "@/components/staff/AddStaffInfo";
import DeleteStaffInfo from "@/components/staff/DeleteStaffInfo";
import EditStaffInfo from "@/components/staff/EditStaffInfo";

export default {
  components: { AddStaffInfo, DeleteStaffInfo, EditStaffInfo },
  data() {
    return {
      msg: "",
      snackbar: false,
      authority: "",
      typeList: [
        { type: "in", text: "入社" },
        { type: "out", text: "退職" },
        { type: "up", text: "昇進" },
        { type: "skill", text: "資格取得" },
      ],
      colorList: { in: "red", out: "blue", up: "orange", skill: "green" },
      selectedTypeList: ["in", "out", "up", "skill"],

      staffinfoList: [],
      staffinfoListHidden: [],
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "日付",
          align: "left",
          sortable: false,
          value: "date",
          width: "20%",
        },
        {
          text: "",
          value: "type",
          filter: this.typeFilter,
          sortable: false,
          width: "15%",
          align: "center",
        },
        { text: "詳細", value: "detail", sortable: false, width: "55%" },
        { text: "", value: "editDelete", sortable: false, width: "10%" },
      ];
    },
    headersForHidden() {
      return [
        {
          text: "日付",
          align: "left",
          sortable: false,
          value: "date",
          width: "15%",
        },
        {
          text: "",
          value: "type",
          sortable: false,
          width: "15%",
          align: "center",
        },
        { text: "詳細", value: "detail", sortable: false, width: "60%" },
        { text: "", value: "editDelete", sortable: false, width: "10%" },
      ];
    },
  },
  methods: {
    typeFilter(value) {
      if (!this.selectedTypeList) {
        return true;
      }
      return this.selectedTypeList.includes(value);
    },
    getUserAuthority() {
      axios
        .get(process.env.VUE_APP_API_URL + "/get-user-authority")
        .then((response) => {
          this.authority = response.data.authority;
        });
    },
    getStaffInfo() {
      axios
        .get(process.env.VUE_APP_API_URL + "/get-staffinfo")
        .then((response) => {
          this.staffinfoList.splice(0);
          this.staffinfoListHidden.splice(0);

          response.data.staffinfoList = _.sortBy(
            response.data.staffinfoList,
            "date"
          ).reverse();
          response.data.staffinfoList.forEach((elem) => {
            const type = this.typeList.find((el) => el.type == elem.type);
            elem["text"] = type.text;
            elem.date = moment(elem.date).format("YYYY-MM-DD");
            if (moment(new Date()).diff(moment(elem.date), "days") <= 30) {
              elem["new"] = true;
            } else {
              elem["new"] = false;
            }
            this.staffinfoList.push(elem);
          });

          response.data.staffinfoListHidden = _.sortBy(
            response.data.staffinfoListHidden,
            "date"
          ).reverse();
          response.data.staffinfoListHidden.forEach((elem) => {
            const type = this.typeList.find((el) => el.type == elem.type);
            elem["text"] = type.text;
            elem.date = moment(elem.date).format("YYYY-MM-DD");
            this.staffinfoListHidden.push(elem);
          });
        });
    },
    deleteStaffInfo(_id) {
      axios
        .post(process.env.VUE_APP_API_URL + "/delete-staffinfo", { _id: _id })
        .then(() => {
          this.msg = "削除しました";
          this.snackbar = true;
          this.getStaffInfo();
        });
    },
    updateStaffInfo() {
      this.msg = "修正しました";
      this.snackbar = true;
      this.getStaffInfo();
    },
  },
  created() {
    this.getUserAuthority();
    this.getStaffInfo();
  },
};
</script>