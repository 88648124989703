<template>
  <v-container fluid class="down-top-padding">
    <v-snackbar top v-model="snackbar" timeout="3000"> {{ msg }} </v-snackbar>
    <v-sheet rounded class="text-h6 pa-2 left-color">社員情報 </v-sheet>
    <v-card class="mt-3">
      <InfoTab></InfoTab>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import _ from "lodash";
import InfoTab from "@/components/staff/InfoTab";

export default {
  name: "StaffPage",

  data: () => ({
    snackbar: false,
    msg: "",
  }),
  components: { InfoTab },
  methods: {
    filter(val, search) {
      return val === search;
    },
    editAbout(event) {
      axios
        .post(process.env.VUE_APP_API_URL + "/update-tsuyukusa-about", {
          about: event,
        })
        .then(() => {
          this.msg = "更新しました。";
          this.getTsuyukusaInfo();
          this.changeFunc();
        });
    },
    editUser(event) {
      axios
        .post(process.env.VUE_APP_API_URL + "/update-tsuyukusa-userlist", {
          userlist: event.editdata,
          nendo: event.newNendo,
        })
        .then(() => {
          this.msg = "更新しました。";
          this.getTsuyukusaInfo();
          this.changeFunc();
        });
    },
    changeFunc() {
      this.snackbar = true;
      setTimeout(() => {
        this.changed = "";
      }, "2000");
    },
    addPayment() {
      this.msg = "登録しました。";
      this.getTsuyukusaInfo();
      this.changeFunc();
    },
    deletePayment(year) {
      axios
        .post(process.env.VUE_APP_API_URL + "/delete-tsuyukusa-payment", {
          year: year,
        })
        .then((response) => {
          if (response.data.result == "OK") {
            this.msg = "削除しました。";
            this.getTsuyukusaInfo();
            this.changeFunc();
          }
        });
    },
    deleteAllowsYear(year) {
      axios
        .post(process.env.VUE_APP_API_URL + "/delete-tsuyukusa-allows-year", {
          year: year,
        })
        .then((response) => {
          if (response.data.result == "OK") {
            this.msg = "削除しました。";
            this.getTsuyukusaInfo();
            this.changeFunc();
          }
        });
    },
    deleteAllow(id) {
      axios
        .post(process.env.VUE_APP_API_URL + "/delete-tsuyukusa-allows", {
          id: id,
        })
        .then((response) => {
          if (response.data.result == "OK") {
            this.msg = "削除しました。";
            this.getTsuyukusaInfo();
            this.changeFunc();
          }
        });
    },
    addAllowYear() {
      this.msg = "登録しました。";
      this.getTsuyukusaInfo();
      this.changeFunc();
    },
    getTsuyukusaInfo() {
      axios
        .get(process.env.VUE_APP_API_URL + "/get-tsuyukusa")
        .then((response) => {
          this.about = response.data.about;
          this.userList.splice(0);
          response.data.userlist.forEach((elem) => {
            this.userList.push(elem);
          });
          this.nendo = response.data.nendo;
          this.payments.splice(0);
          response.data.payments = _.sortBy(
            response.data.payments,
            "year"
          ).reverse();
          response.data.payments.forEach((elem) => {
            this.payments.push(elem);
          });
          this.allows.splice(0);
          response.data.allows = _.sortBy(
            response.data.allows,
            "year"
          ).reverse();
          response.data.allows.forEach((elem) => {
            elem.detail = _.sortBy(elem.detail, "date").reverse();
            this.allows.push(elem);
          });
        });
    },
    getUserAuthority() {
      axios
        .get(process.env.VUE_APP_API_URL + "/get-user-authority")
        .then((response) => {
          this.authority = response.data.authority;
        });
    },
  },

  created() {
    this.getUserAuthority();
  },
};
</script>
<style>
.detail {
  white-space: pre-line;
}
table tr:nth-child(odd) td {
  background: #9696961a;
}
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
.chip {
  width: 70px;
  justify-content: center;
  align-items: center;
}
</style>
