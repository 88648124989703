<template>
  <v-dialog v-model="dialog" persistent max-width="600">
    <v-snackbar top v-model="snackbar" timeout="3000"> {{ msg }} </v-snackbar>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        fab
        dark
        v-bind="attrs"
        v-on="on"
        class="fixed_btn"
      >
        <v-icon dark> mdi-plus </v-icon>
      </v-btn>
    </template>
    <v-card class="pa-3">
      <v-form v-model="valid" ref="form" @submit.prevent>
        <v-card-title class="text-h5"> 社員情報 追加 </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-text-field
                :rules="dateRule"
                label="日付"
                v-model="registData.date"
                hint="YYYYMMDD"
              >
              </v-text-field
            ></v-col>
            <v-col cols="6">
              <v-select
                :rules="selectfieldRule"
                :items="typeList"
                label="選択"
                item-text="text"
                item-value="type"
                v-model="registData.type"
              >
              </v-select
            ></v-col>
          </v-row>

          <v-text-field
            :rules="selectfieldRule"
            label="詳細"
            v-model="registData.detail"
            @keydown.enter="regist()"
          >
          </v-text-field>
        </v-card-text>
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="cancel()"> 閉じる </v-btn>
        <v-btn color="primary" text @click="regist()"> 登録 </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
import moment from "moment";

export default {
  components: {},
  props: ["typeList"],
  data() {
    return {
      dialog: false,
      snackbar: false,
      msg: "",
      valid: false,
      selectfieldRule: [(v) => !!v || "入力してください"],
      dateRule: [
        (v) => !!v || "入力してください",
        (v) => /^[0-9]{8}/.test(v) || "YYYYMMDDの形式で入力してください",
        (v) => moment(v).isValid() || "正しい日付を入力してください",
      ],
      registData: {
        date: "",
        type: "",
        detail: "",
      },
    };
  },

  methods: {
    clear() {
      this.registData = {
        date: "",
        type: "",
        detail: "",
      };
      this.$refs.form.reset();
      this.$emit("my-click");
    },
    cancel() {
      this.clear();
      this.dialog = false;
    },
    regist() {
      if (this.$refs.form.validate()) {
        axios
          .post(process.env.VUE_APP_API_URL + "/regist-staffinfo", {
            registData: this.registData,
          })
          .then((response) => {
            if (response.data.result == "OK") {
              this.clear();
              this.msg = "登録しました";
              this.snackbar = true;
            }
          });
      }
    },
  },
};
</script>
<style>
.fixed_btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 10000;
}
</style>