<template>
  <v-dialog v-model="dialog" persistent max-width="800">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on" @click="editItem()"> mdi-pencil </v-icon>
    </template>
    <v-card class="pa-3">
      <v-form v-model="valid" ref="form" @submit.prevent>
        <v-card-title class="text-h5"> 新人情報 修正 </v-card-title>
        <v-card-text>
          <v-img :src="registData.photo" max-width="200"></v-img>
          <v-file-input
            class="ma-0"
            label="新しい写真の選択"
            v-model="registData.newPhoto"
            accept=".png,.jpg,.jpeg"
            :rules="newPhotoRules"
          ></v-file-input>

          <v-row>
            <v-col cols="3">
              <v-text-field
                :rules="nendoRule"
                label="年度"
                v-model="registData.nendo"
                hint="YYYY"
              >
              </v-text-field>
            </v-col>
            <v-col cols="9">
              <v-text-field
                :rules="selectfieldRule"
                label="名前"
                v-model="registData.name"
              >
              </v-text-field
            ></v-col>
          </v-row>
          <v-text-field
            :rules="selectfieldRule"
            label="経歴"
            v-model="registData.career"
          >
          </v-text-field>
          <v-textarea
            class="mt-5"
            :rules="selectfieldRule"
            label="コメント"
            v-model="registData.comment"
            outlined
            rows="10"
          >
          </v-textarea>
          <v-row>
            <v-col cols="6">
              <v-text-field
                :rules="dateRule"
                label="掲載日"
                v-model="registData.dispdate"
                hint="YYYYMMDD"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="cancel()"> 閉じる </v-btn>
        <v-btn color="primary" text @click="regist()"> 登録 </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
import moment from "moment";

export default {
  props: ["item"],
  components: {},
  data() {
    return {
      dialog: false,
      snackbar: false,
      msg: "",
      valid: false,
      nendoRule: [
        (v) => !!v || "入力してください",
        (v) => /^[0-9]{4}$/.test(v) || "YYYYの形式で入力してください",
      ],
      selectfieldRule: [(v) => !!v || "入力してください"],
      dateRule: [
        (v) => !!v || "入力してください",
        (v) => /^[0-9]{8}/.test(v) || "YYYYMMDDの形式で入力してください",
        (v) => moment(v).isValid() || "正しい日付を入力してください",
      ],
      photoRules: [
        (v) => !!v || "ファイルを選択してください",
        (v) =>
          !v ||
          v.type == "image/png" ||
          v.type == "image/jpg" ||
          v.type == "image/jpeg" ||
          '"png,jpg,jpeg形式の画像ファイルを選択してください"',
      ],
      newPhotoRules: [
        (v) =>
          !v ||
          v.type == "image/png" ||
          v.type == "image/jpg" ||
          v.type == "image/jpeg" ||
          '"png,jpg,jpeg形式の画像ファイルを選択してください"',
      ],
      registData: {
        _id: "",
        nendo: "",
        name: "",
        career: "",
        comment: "",
        dispdate: "",
        photo: "",
        newPhoto: null,
      },
    };
  },

  methods: {
    editItem() {
      this.registData = this.item;
    },
    clear() {
      this.registData = {
        _id: "",
        nendo: "",
        name: "",
        career: "",
        comment: "",
        dispdate: "",
        photo: "",
        newPhoto: null,
      };
      this.$refs.form.reset();
      this.dialog = false;
    },
    cancel() {
      this.clear();
      this.dialog = false;
    },
    regist() {
      if (this.$refs.form.validate()) {
        const senddata = new FormData();
        senddata.append("_id", this.registData._id);
        senddata.append("nendo", this.registData.nendo);
        senddata.append("name", this.registData.name);
        senddata.append("career", this.registData.career);
        senddata.append("comment", this.registData.comment);
        senddata.append("dispdate", this.registData.dispdate);
        senddata.append("photo", this.registData.photo);
        senddata.append("files", this.registData.newPhoto);

        axios
          .post(process.env.VUE_APP_API_URL + `/update-newcomer`, senddata, {
            headers: { "content-type": "multipart/form-data" },
          })
          .then(() => {
            this.clear();
            this.dialog = false;
            this.$emit("my-click");
          });
      }
    },
  },
};
</script>
<style>
.fixed_btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 10000;
}
</style>